<template>
  <section class="hero d-flex align-items-center section-bg">
    <div class="container">
      <div class="row justify-content-between gy-5">
        <div
          class="col-lg-5 order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
          <h2 class="main-title">Disfruta de la <br>mejor pasta</h2>
          <p class="secondary-title">Tradición desde 1956</p>
          <div class="d-flex">
            <router-link class="btn btn-danger btn-order-link" to="/orders/create" :class="{ 'disabled': onlyWhatsapp }">Haz tu pedido </router-link>
          </div>
          <small v-if="onlyWhatsapp" class="text-primary">Sólo Whatsapp!</small>
        </div>
        <div class="col-lg-5 order-1 order-lg-2 text-center text-lg-start">
          <img src="@/assets/img/hero_pz.png" class="img-fluid" alt="" data-aos="zoom-out" data-aos-delay="300">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      onlyWhatsapp: false
    }
  },
}
</script>

<style scoped>
.main-title {
  font-size: 50px;
}

.secondary-title {
  font-size: 26px;
  font-family: Georgia, serif;
}

.btn-order-link {
  font-size: 1.1em;
  padding: 10px 20px;
}

@media (max-width: 768px) {
  .img-fluid {
    height: 300px;
  }
}
</style>
