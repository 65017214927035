import "bootstrap/dist/css/bootstrap.min.css"

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { toast } from 'vue3-toastify'

import { http } from '@/utils/http'

/* Default title tag */
store.dispatch('text/setLanguage', { language: 'es' })

/* Vendor CSS Files */
import '@/assets/vendor/bootstrap/css/bootstrap.min.css'
import '@/assets/vendor/bootstrap-icons/bootstrap-icons.css'

/* Styles */
import '@/assets/css/main.css'

// createApp(App).use(store).use(router).mount('#app')
const app = createApp(App).use(store).use(router)

// Definir una variable global
app.config.globalProperties.$toastOptions = {
    autoClose: 2500,
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
    transition: toast.TRANSITIONS.SLIDE,
    theme: 'colored',
    icon: true,
    newestOnTop: true,
    pauseOnFocusLoss: false,
    clearOnUrlChange: false,
}

app.config.globalProperties.$postActivityLog = function(pass_at, pass_details) {
    const params = {
        user_id: store.state.user.id ?? 0,
        activity_type: pass_at,
        details: pass_details,
    }
    http().post('/activity_logs', params )
}

app.mount('#app');

import "bootstrap/dist/js/bootstrap.js"