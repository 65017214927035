export default {

    // General
    name: 'Nombre',
    submit: 'Enviar',
    hidden: 'Oculto',
    save: 'Guardar',
    close: 'Cerrar',
    delete: 'Eliminar',
    logout: 'Cerrar Sesión',
    firstname: 'Primer Nombre',
    middlename: 'Segundo Nombre',
    lastname: 'Apellido',
    lastname1: 'Apellido Paterno',
    lastname2: 'Apellido Materno',
    email: 'Correo',
    phone: 'Teléfono',
    actions: 'Acciones',
    date: 'Fecha',
    store: 'Tienda',
    total: 'Total',
    shortname: 'Abreviatura',
    undefined: 'No definido',
    insertedBy: 'Ingresado por',
    modifiedBy: 'Modificado por',
    insertedAt: 'Ingresado',
    modifiedAt: 'Modificado',
    enabled: 'Habilitado',
    yes: 'Sí',
    no: 'No',
    description: 'Descripción',
    title: 'Título',
    subtitle: 'Subtítulo',
    slug: 'Slug',
    generate: 'Generar',
    product: 'Producto',
    products: 'Productos',
    selectOption: 'Selecciona una opción',
    unit: 'Unidad',
    weight: 'Peso',
    toLink: 'Enlazar',
    value: 'Valor',
    measure: 'Medida',
    count: 'Conteo',
    id: 'Identificador',
    address: 'Dirección',
    additionalInfo: 'Información Adicional',
    detail: 'Detalle',
    details: 'Detalles',
    info: 'Info.',
    information: 'Información',
    customerInfo: 'Información Cliente',
    orderInfo: 'Información Pedido',
    deliveryInfo: 'Información Reparto',
    documentId: 'RUT',
    birthdate: 'Fecha nacimiento',
    gender: 'Género',
    male: 'Masculino',
    female: 'Femenino',
    quantity: 'Cantidad',
    comments: 'Comentarios',
    status: 'Estado',
    history: 'Historial',
    createdAt: 'Creado en',
    subtotal: 'Subtotal',
    next: 'Siguiente',
    previous: 'Anterior',
    and: 'y',
    label: 'Etiqueta',
    reject: 'Rechazar',
    download: 'Descargar Archivo',
    summary: 'Consolidado',
    completeTheFields: 'Completa los campos',
    of: 'de',
    consolidated: 'Consolidado',
    goto: 'Ir a',
    type: 'Tipo',
    
}  