<template>
  <header-bar />
  <router-view/>
  <footer-bar />
</template>

<script>
  import HeaderBar from '@/components/HeaderBar.vue'
  import FooterBar from '@/components/FooterBar.vue'
  import 'vue3-toastify/dist/index.css'

  export default {
    components: {
      HeaderBar,
      FooterBar
    },
    data () {
      return {
        
      }
    },
    watch:{
      $route (){
        window.scroll(0,0)
      }
    },
    mounted(){
      this.$store.commit('initializeStore')
      // console.log('msg from App.vue')
    }
  }
</script>