import text from '@/text'

const textModule = {
  namespaced: true,
  state: () => ({
    currentLanguage: 'es',
    text: null
  }),
  getters: { },
  mutations: {
    setLanguage (state, language) {
      state.currentLanguage = language
      state.text = text[language]
    }
  },
  actions: {
    setLanguage ({ commit }, payload) {
      commit('setLanguage', payload.language)
    }
  }
}

export default textModule
