<template>
    <header>
        <nav class="navbar fixed-top navbar-expand-lg">
            <div class="container">
                <a class="navbar-brand col-md-2 ps-1" href="#"><h1>PASTAS ZENA</h1></a>
                <button class="navbar-toggler" @click="visible=!visible" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-collapse" :class="!visible?'collapse':''" id="navbarNav">
                    <ul class="navbar-nav nav col-md-9  mb-2  mb-md-0">
                        <li class="nav-item">
                            <router-link class="nav-link" to="/" @click="visible=!visible">Inicio</router-link>
                        </li>
                        <li class="nav-item" >
                            <router-link class="nav-link" to="/products" @click="visible=!visible">Productos</router-link>
                        </li>
                        <li class="nav-item" >
                            <router-link class="nav-link" to="/instructions" @click="visible=!visible">Preparaciones</router-link>
                        </li>
                        <li class="nav-item" style="margin-right: 10px;">
                            <router-link class="nav-link" to="/stores" @click="visible=!visible">Tienda</router-link>
                        </li>
                        <li class="nav-item" style="margin-right: 10px;">
                            <router-link class="nav-link" to="/customers" @click="visible=!visible">Clientes</router-link>
                        </li>
                        <li class="nav-item mt-1" >
                            <router-link class="btn btn-danger" :class="{ 'disabled': onlyWhatsapp }"  to="/orders/create" @click="visible=!visible">Haz tu pedido </router-link>
                        </li>
                        <small v-if="onlyWhatsapp" class="text-primary">Sólo Whatsapp!</small>
                    </ul>
                    <ul v-if="!userLogged" class="navbar-nav nav col-md-3  mb-2  mb-md-0 d-none">
                        <li  class="nav-item">
                            <router-link class="nav-link" to="/login">Inicia Sesión</router-link>
                        </li>
                        <li class="nav-item d-none">
                            <a class="nav-link" href="#">Carrito</a>
                        </li>
                    </ul>
                    <ul v-if="userLogged" class="navbar-nav nav col-md-3  mb-2  mb-md-0"> 
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle second-text fw-bold" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fas fa-user" ></i>Hola {{ username }}!
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <li>
                                    <router-link class="dropdown-item" to="/profile">
                                        <i class="me-1 bi bi-person icon"></i> Perfil
                                    </router-link>
                                    <router-link class="dropdown-item" to="/my-orders">
                                        <i class="me-1 bi bi-list-check icon"></i> Mis Pedidos
                                    </router-link>
                                    <a @click="logout" class="dropdown-item ">
                                        <i class="me-1 bi bi-box-arrow-right icon text-danger"></i> Cerrar Sesión
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'HeaderBar',
        components: { },
        data () {
            return {
                userLogged: false,
                username: '',
                visible: false,
                onlyWhatsapp: false
            }
        },
        watch: {
            $route (){
                if(localStorage.getItem('email')){
                    this.userLogged = true;
                    this.username = localStorage.getItem('username')
                }
            }
        },
        methods: {
            logout(){
                this.$store.commit('logout')
                this.userLogged = false;
                this.$router.push('/')
            }
        },
        mounted () {
            if(localStorage.getItem('email')){
                this.userLogged = true;
                this.username = localStorage.getItem('username')
            }
        }
    })

</script>

<style scoped>
    .navbar-brand {
        font-family: 'Copperplate Gothic Bold';
        font-weight: bold;
    }
    a.navbar-brand h1{
        color:#b02a37;
        color:#094323;
        margin-top:1px;
    }

    a.nav-link {
        font-weight: 600;
        font-size: 16px;
    }

    .dropdown-menu {
        border-radius: 0;
    }

    .dropdown-menu a{
        cursor: pointer;
    }

</style>