<template>
  <footer id="footer" class="footer">

  <div class="container">
    <div class="row gy-3">
      <div class="col-lg-3 col-md-6 d-flex ps-4">
        <i class="bi bi-whatsapp icon"></i>

        <div>
          <h4>+569 6385 4046</h4>
          <p>
            Valparaíso <br>
            Simón Bolivar #442<br>
            <strong>(32) 223 8856</strong>
          </p>
          <p class="d-none">
            Viña Del Mar <br>
            Quillota #624<br>
            <strong>(65) 275 3952</strong>
          </p>
        </div>

      </div>

      <div class="col-lg-3 col-md-6 footer-links d-flex ps-4">
        <i class="bi bi-clock icon"></i>
        <div>
          <h4>Horarios</h4>
          <p>
            Valparaíso<br>
            <strong>Martes-viernes: </strong>9AM - 6PM<br>
            <strong>Sábados: </strong>9AM - 4PM<br>
            <strong>Domingos: </strong>11AM - 2PM<br>
            <strong>Lunes: </strong>Cerrado<br>
          </p>

          <p class="d-none">
            Viña del Mar<br>
            <strong>Martes-viernes: </strong>10AM - 7PM<br>
            <strong>Sábados: </strong>10AM - 4PM<br>
            <strong>Domingos: </strong>11AM - 2PM<br>
            <strong>Lunes: </strong>Cerrado<br>
          </p>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 footer-links ps-4">
        <div >
          <i class="bi bi-truck icon "></i>
          <h4 class="d-inline">
            <router-link to="/localities">Valores de Despacho</router-link>
          </h4>
        </div>
        <br>
        <div>
          <i class="bi bi-search icon "></i>
          <h4 class="d-inline">
            <router-link to="/track-order">Consultar Pedido</router-link>
          </h4>
        </div>
        <br>
        <div>
          <i class="bi bi-person-badge-fill icon "></i>
          <h4 class="d-inline">
            <a href="https://admin.pastaszena.cl">Acceso Admin</a>
          </h4>
        </div>
        <br>
        <div class="d-none">
          <i class="bi bi-question-circle icon "></i>
          <h4 class="d-inline">Preguntas Frecuentes</h4>
        </div>
        <br>
        <div class="d-none">
          <i class="bi bi-chat-right-text icon "></i>
          <h4 class="d-inline">Sugerencias y Reclamos</h4>
        </div>
        <br>
        <div class="d-none">
          <i class="bi bi-person-workspace icon "></i>
          <h4 class="d-inline">Trabaja con Nosotros</h4>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 footer-links ">
        <h4 class="text-center">Síguenos en</h4>
        <div class="social-links d-flex justify-content-center">
          <a href="https://www.instagram.com/pastas.zena" class="twitter"><i class="bi bi-instagram"></i></a>
          <a href="https://www.facebook.com/PastasZena" class="facebook"><i class="bi bi-facebook"></i></a>
          <a href="https://www.youtube.com/@pastaszena" class="instagram"><i class="bi bi-youtube"></i></a>
          <a href="https://www.linkedin.com/company/pastas-zena" class="linkedin"><i class="bi bi-linkedin"></i></a>
          <a href="#" class="linkedin d-none"><i class="bi bi-linkedin"></i></a>
        </div>
      </div>

    </div>
  </div>

  <div class="container">
    <div class="copyright navbar-brand">
      <h4>PASTAS ZENA</h4>
    </div>
  </div>

  </footer><!-- End Footer -->
</template>

<style scoped>
  .navbar-brand {
      font-family: 'Copperplate Gothic Bold';
      font-weight: bold;
  }
  a {
    color:white;
  }
  a:hover {
    color:#0d6efd;
  }

</style>