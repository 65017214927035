import { createStore } from 'vuex'

import text from './modules/text'

export default createStore({
  state: {
    user: 
      {
        id: null,
        email: null,
      }
    ,
  },
  getters: {
  },
  mutations: {
    login(state, args){
      state.user.id = args.userId
      state.user.email = args.email
      state.user.customer_id = args.customerId
      localStorage.setItem('userId',args.userId)
      localStorage.setItem('email',args.email)
      localStorage.setItem('customerId',args.customerId)
      localStorage.setItem('username',args.username)
      localStorage.setItem('token',args.token)
    },
    logout(state){
      state.user = {},
      localStorage.clear()
    },
    initializeStore(state){
      if(localStorage.getItem('email')){
        state.user.id = localStorage.getItem('userId')
        state.user.customer_id = localStorage.getItem('customerId')
        state.user.email = localStorage.getItem('email')
      }
    }
  },
  actions: {
  },
  modules: {
    text
  }
})
