import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    meta: {
      title: 'Inicio',
    },
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    meta: {
      title: 'Productos',
    },
    path: '/products',
    name: 'products',
    component: () => import('../views/products/Products.vue')
  },
  {
    meta: {
      title: 'Preparaciones',
    },
    path: '/instructions',
    name: 'instructions',
    component: () => import('../views/instructions/Instructions.vue')
  },
  {
    meta: {
      title: 'Tiendas',
    },
    path: '/stores',
    name: 'stores',
    component: () => import('../views/StoresView.vue')
  },
  {
    meta: {
      title: 'Nuestros Clientes',
    },
    path: '/customers',
    name: 'customers',
    component: () => import('../views/CustomersView.vue')
  },
  {
    meta: {
      title: 'Valores de Despacho',
    },
    path: '/localities',
    name: 'localities',
    component: () => import('../views/LocalitiesView.vue')
  },
  {
    meta: {
      title: 'Haz tu Pedido',
    },
    path: '/orders/create',
    name: 'orders.create',
    component: () => import('../views/orders/OrdersCreate.vue')
  },
  {
    meta: {
      title: 'Inicia Sesión'
    },
    path: '/login',
    name: 'login',
    component: () => import('../views/users/Login.vue')
  },
  {
    meta: {
      title: 'Crea tu cuenta'
    },
    path: '/register',
    name: 'register',
    component: () => import('../views/users/Register.vue')
  },
  {
    meta: {
      title: 'Recuperar Contraseña'
    },
    path: '/recover-password',
    name: 'recover.password',
    component: () => import('../views/users/RecoverPassword.vue')
  },
  {
    meta: {
      title: 'Resetear Contraseña'
    },
    path: '/reset-password/:token',
    name: 'reset.password',
    component: () => import('../views/users/ResetPassword.vue'),
    props: true
  },
  {
    meta: {
      title: 'Verify'
    },
    path: '/verify-email',
    name: 'verify.email',
    component: () => import('../views/users/Verify.vue'),
    //props: true
  },
  {
    meta: {
      title: 'Perfil'
    },
    path: '/profile',
    name: 'profile',
    component: () => import('../views/users/Profile.vue'),
    //props: true
  },
  {
    meta: {
      title: 'Agregar Dirección'
    },
    path: '/profile/add_address',
    name: 'profile.add_address',
    component: () => import('../views/users/AddAddress.vue'),
    //props: true
  },
  {
    meta: {
      title: 'Seguir Pedido'
    },
    path: '/track-order',
    name: 'track.order',
    component: () => import('../views/orders/TrackOrders.vue'),
    //props: true
  },
  {
    meta: {
      title: 'Mi Pedido'
    },
    path: '/track-order/:id',
    name: 'track.order.view',
    component: () => import('../views/orders/TrackOrdersView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Mis Pedidos'
    },
    path: '/my-orders',
    name: 'orders.list',
    component: () => import('../views/orders/MyOrders.vue'),
    //props: true
  },
  {
    meta: {
      title: 'Datos bancarios'
    },
    path: '/bank-account-details',
    name: 'bank.account.details',
    component: () => import('../views/payments/BankAccountDetails.vue'),
    //props: true
  },
  {
    meta: {
      title: 'Pago Exitoso'
    },
    path: '/payments/success',
    name: 'payments.success',
    component: () => import('../views/payments/Success.vue'),
    //props: true
  },
  {
    meta: {
      title: 'Pago con Error'
    },
    path: '/payments/failure',
    name: 'payments.failure',
    component: () => import('../views/payments/Failure.vue'),
    //props: true
  },
  {
    meta: {
      title: 'Pagos'
    },
    path: '/payments/process/:token',
    name: 'payments.process',
    component: () => import('../views/payments/Process.vue'),
    props: true
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to) => {

  document.title = to.meta.title;
  // next();
  
})

export default router
